import { Footer } from "../components/Footer";
import HeaderTopComponent from "../components/Header/HeaderTopComponent";
import NewsActiveItem from "../components/News/NewsActive";
import { useLoaderData } from 'react-router-dom';
import axios from 'axios';
import AxiosInstance from "../components/Axios";
import { useEffect, useState } from "react";


  export async function LoaderNewsUpdate({ params }) {

    if (Object.keys(params).length != 0) {
      let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/news/' + params.id).then((response) => {
        return response;
      });
      return res;
    }
    return false;
  };





const NewsActive = () => {
  const [news, setNews] = useState([])
  useEffect(() => {

    AxiosInstance.get(`https://api.kramline.com/api/news?page=1&itemsPerPage=30`).then((response) => {
      if (response.status === 200)
      return (
        setNews(response.data['hydra:member'])  
        );
    });
  }, []);

  const { data } = useLoaderData();

  return (
    <>
    <HeaderTopComponent  />
    <NewsActiveItem data={data} news={news} />
    <Footer />
    </>
  )
}

export default NewsActive;