import React from "react";
import { HeaderAns } from "../components/Header/HeaderAns";
import { Categories } from "../components/Categories";
import { Footer } from "../components/Footer";

// import axios from 'axios';
// import jwt from 'jwt-decode' // import dependency;
// import AxiosInstance from './Axios';

const Answer = () => {
  const key = localStorage.getItem('user')

  return (

    <>

      {(key) ? (
        <>
          <HeaderAns />
          <Categories />
          <Footer />
        </>
      ) : (
        <div style={{ fontSize: '30px' }}>
          Сторінку не знайдено
        </div>
      )}

    </>

  );
}

export default Answer;