// import React from "react";
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import AxiosInstance from '../Axios';
import { Link } from 'react-router-dom';


const LastNews = ({ news }) => {
    const [visibleNews, setVisibleNews] = useState(4)

    useEffect(() => {
        if (visibleNews < news.length) {
            return;
        }
    }, [visibleNews]);

    const showMoreNews = () => {
        setVisibleNews(visibleNews + 4);
    }
    return (
        <>
            {/* <Container> */}
            <Row>
                <Col md={12} >
                    <h2 className="advantages__title title">
                        Новини
                    </h2>
                </Col>
                </Row>
                <div className='news__container'>
                    <div className="Lastnews">

                        <div className="news__content">
                            {
                                news.slice(0, visibleNews).map((item, index) => (
                                    <div className="news__item" key={index}>
                                        <div className="newsItem__img">
                                            {item.video_img ? <img src={item.video_img} alt="" /> : <img src="./../../img/news_1.png" alt="" />}
                                        </div>
                                        <div className="newsItem__wrapper">
                                            <div className="newsItem__title">
                                                {item.name}
                                            </div>
                                            <div className="newsItem__subTitle">
                                                {item.news_shot}
                                            </div>
                                            <a href={`/news/${item.id}`} className="newsItem__btn">
                                                Читати більше
                                            </a>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='news__wrapper--btnMore'>
                    <a href='/news' className='news__more'>Детальніше</a>
                </div>

                {/* </Container> */}
            </>
            )
}

            export default LastNews;