import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";
import { HeaderTopF } from "./HeaderTopF";
import { HeaderTopBurgerF } from "./HeaderTopBurgerF";
import HeaderTopComponent from "./HeaderTopComponent";
import { HeaderAns } from "./HeaderAns";
import AxiosInstance from "../Axios";

export const Header = () => {
  const [isVisible, setIsVisible] = useState();
  const [yourVariable, setYourVariable] = useState(null);

  useEffect(() => {
    // Код, який має бути виконаний після рендерингу компонента

    // Перевірка, чи змінна ще не була створена
    if (yourVariable === null) {
      // Створення змінної
      setYourVariable(document.getElementById('sub_form'));
    }
  }, [yourVariable]);

  const scrToBtn = () => {
    if(yourVariable !== null) {
      yourVariable.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  const handleButtonClick = () => {
    setIsVisible((prevVisible) => !prevVisible);
  };

  const [mainPhoto, setMainPhoto] = useState([])
  useEffect(() => {

    AxiosInstance.get(`https://api.kramline.com/api/menus?page=1&itemsPerPage=30&type=main_photo_header`).then((response) => {
      if (response.status === 200)
      return (
        setMainPhoto(response.data['hydra:member'][0].menuItems[0].slug)  
        );
    });
  }, []);
  // console.log(photoHistory[0].menuItems[0].slug)
  console.log(mainPhoto)


  return (
    <header className="header">
      {/* {!isVisible && (
        <HeaderTopF handleButtonClick={handleButtonClick} />
      )}
      {isVisible && (
        <HeaderTopBurgerF handleButtonClick={handleButtonClick} />
      )} */}
      <HeaderTopComponent />
      {/* <HeaderAns /> */}
      <div className="header__bg" style={{backgroundImage: `url(${mainPhoto})`}}>
        <Container>
          <Row>
            <Col>
              <h2 className="header__title">
                Kramline — це більше, ніж франшиза рибних магазинів
              </h2>
              <p className="header__text">
                Це ціла рибна культура, ретельно створена компанією <span style={{ fontWeight: '800' }}>KRAMAR LTD</span>, яка має на меті забезпечити споживачів широким асортиментом сертифікованої риби та морепродуктів
              </p>
              {/* <a href="#sub_form" className="header__btn"> */}
              <a  className="header__btn" onClick={scrToBtn}>
                Залишити заявку
              </a>

            </Col>
          </Row>
        </Container>
      </div>
      <div className="header__bottom">
        <Container>
          <Row>
            <Col xs={12} lg={4}>
              <div className="header__bottom--card">
                <p className="header__bottom--price">
                  400 000 грн
                </p>
                <p className="header__bottom--text">
                  Паушальний внесок
                </p>
                <div className="header__bottom--line"></div>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div className="header__bottom--card">
                <p className="header__bottom--price">
                  950 000 грн
                </p>
                <p className="header__bottom--text">
                  Виручка (середня на місяць)
                </p>
                <div className="header__bottom--line"></div>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div className="header__bottom--card">
                <p className="header__bottom--price">
                  12-16 міс.
                </p>
                <p className="header__bottom--text">
                  Окупність інвестиції
                </p>
                <div className="header__bottom--line"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  )
}