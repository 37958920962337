import { useState } from "react";
import { Footer } from "../components/Footer";
import HeaderTopComponent from "../components/Header/HeaderTopComponent";
import MainMew from "../components/News/MainNew";
import  NewsList  from "../components/News/NewsList";
import { useEffect } from "react";
import AxiosInstance from "../components/Axios";
import { HeaderAns } from "../components/Header/HeaderAns";

const News = () => {

  const [news, setNews] = useState([])
  useEffect(() => {

    AxiosInstance.get(`https://api.kramline.com/api/news?page=1&itemsPerPage=30`).then((response) => {
      if (response.status === 200)
      return (
        setNews(response.data['hydra:member'])  
        );
    });
  }, []);
  console.log(news)
  return (
    <>
    {/* <HeaderTopComponent /> */}
    <HeaderAns />
    {/* <MainMew newa={news} /> */}
    <div style={{height: '100px'}}></div>
    <NewsList news={news}/>
    <Footer />
    </>
  )
}

export default News;