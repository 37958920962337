import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logout from "../Logout";

export const HeaderTopBurger = (handleButtonClick) => {
  const key = localStorage.getItem('user')

  return (
    <div className="header-burger__top header-burger__top--answer">
      <Container>
        <Link to="/answer" className="header-burger__close" onClick={handleButtonClick}></Link>
        <nav className="header-burger__top--flex">
          <Link href="/">
            <img className="header-burger__logo" src="../img/logo.svg" alt="logo" />
          </Link>
          <ul className="header-burger__nav">
            <li>
              <Link to="/" className="header-burger__nav-link">Головна</Link>
            </li>
            <li>
              <Link to="/#advantages" id="lingToAdvantages" className="header-burger__nav-link">Франшиза</Link>
            </li>
            <li>
              <Link to="/#advantages" id="lingToAdvantages" className="header-burger__nav-link">Переваги</Link>
            </li>
            <li>
              <Link to="/news" className="header-burger__nav-link">Новини</Link>
            </li>
            {(key) && (
              <>
                <li>
                  <Link to="/answer" className="header-burger__nav-link">Часті питання</Link>
                </li>
              </>
            )}
            <li>
              <a href="#scrff" className="header-burger__nav-link">Контакти</a>
            </li>
            {(key) && (
              <>
                <li>
                  <Link to="/" className="header-burger__nav-link" onClick={logout}>Вийти</Link>
                </li>
              </>
            )}
            {(!key) && (
              <>
                <li>
                  <a href="/login" className="header-burger__nav-link">
                    <img src="../img/profile.png" alt="profile" /></a>
                </li>
              </>
            )}
          </ul>
        </nav>
      </Container>
    </div>
  )
}