import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AxiosInstance from "./Axios";

export const History = () => {
  const [photoHistory, setPhotoHistory] = useState([])
  useEffect(() => {

    AxiosInstance.get(`https://api.kramline.com/api/menus?page=1&itemsPerPage=30&type=main_photo_short_history`).then((response) => {
      if (response.status === 200)
      return (
        setPhotoHistory(response.data['hydra:member'][0].menuItems[0].slug)  
        );
    });
  }, []);

  return (
    <Container className="history">
      <Row className="history__content-sm">
        <Col xs={12}>
          <div className="history__content-sm--container">
          {/* <img className="history__img-2" src="../img/kramar_build.png" alt="" style={{ marginBottom: '25px' }} /> */}
          <img className="history__img-2" src={photoHistory} alt="" style={{ marginBottom: '25px' }} />
          </div>
        </Col>
      </Row>
      <Row className="history__top">
        <Col xs={12}>
          <h2 className="history__title title">
            Kramline — торгова марка компанії
            <br />
            Kramar LTD
          </h2>
        </Col>
        <Col>
          <p className="history__text">
            Kramar ltd — найбільший імпортер риби на заході України. Також входимо в 7-ку лідерів постачання риби в Україні. Доставляємо лише сертифіковану продукцію з найбільших морських держав світу. Крім того, у Львівській області вже успішно працюють 19 власних магазинів торгової марки KRAMAR-SHOP.
          </p>
        </Col>
      </Row>
      <Row className="history__content-lg">
        <Col xs={12} md={7}>
          <img className="history__img-1" src="../img/short_history.png" alt="" />
        </Col>
        <Col xs={12} md={5}>
          {/* <img className="history__img-2" src="../img/kramar_build.png" alt="" /> */}
          <img className="history__img-2" src={photoHistory} alt="" />
        </Col>
      </Row>
      <Row className="history__content-sm">
        <Col xs={12}>
          <div className="history__content hist">
            <div className="hist__title">
              Коротка історія:
            </div>
            <div className="hist__line-1"></div>
            <div className="hist__year">
              2007 р.
            </div>
            <div className="hist__text">
              заснування
            </div>
            <div className="hist__line-2"></div>
            <div className="hist__year">
              2012р.
            </div>
            <div className="hist__text">
              збудовано 3000 м<sup>2</sup> складів
            </div>
            <div className="hist__line-3"></div>
            <div className="hist__year">
              2019 р.
            </div>
            <div className="hist__text">
              збудовано рибний цех
            </div>
            <div className="hist__line-4"></div>
            <div className="hist__year">
              2022 р.
            </div>
            <div className="hist__text">
              відкритий
              рибний ресторан,
              запуск лінії пресерв, а також з’явилась торгова марка власного виробництва Kramline
            </div>
            <div className="hist__line-5"></div>
            <div className="hist__year">
              2023 р.
            </div>
            <div className="hist__text">
              відкрито
              19 роздрібних магазинів Kramar-shop. Запуск франшизи
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}