import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'


export const Stages = () => {


  return (
    <Container className="stages">
      <Row>
        <h2 className="title stages__title">
          Етапи відкриття магазину
        </h2>
        <p className="stages__subtitle">
          Орієнтовно після підписання договору необхідно 30 днів до відкриття
        </p>
      </Row>
      <Row className="stages__content--phone">
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--1">
            Залишіть заявку
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--2">
            Дослідження об’єкту
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--3">
            Підписання договору
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--4">
            Технічні креслення та дизайн магазину
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--5">
            Замовлення холодильного та морозильного обладнання і меблів
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--6">
            Розробка CRM
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--7">
            Погодження вивіски
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--8">
            Навчання персоналу
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--9">
            Ремонт
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--10">
            Відкриття
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6}>
          <div className="stages__card stages__card--11">
            Контроль та підтримка подальшої діяльності з боку франчайзера
          </div>
        </Col>
      </Row>
      <Row>
        <div className="stages__content">
        </div>
      </Row>
    </Container>
  )
}