import axios from 'axios';


  

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + '/api',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    // "Authorization" : `Bearer ${tokenStr}`,
    // 'accept': 'application/json'

  },
});

AxiosInstance.interceptors.request.use(function (config) {

  
    if(config.url === '/auth'){
      config.baseURL = process.env.REACT_APP_SERVER_URL;
    }
    
    const tokenStr = localStorage.getItem('user');
    const exp = localStorage.getItem('user_exp');
    if (tokenStr && exp) {
      const dateNow = new Date();
      const dateSession = new Date( parseInt( exp ) * 1000 );
      if(dateNow <= dateSession){
        config.headers['Authorization'] = `Bearer ${tokenStr}`;
      }
    }
    return config;
});


AxiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response;
  },function (error) {
    
    if (error.response && error.code === "ERR_NETWORK") {
        alert('Помилка зєднання з сервером!')
    }
  
    return Promise.reject(error);
});

export default AxiosInstance;
