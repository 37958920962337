import './../styles/global.scss';


import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from './Home';
import NoPage from './NoPage';
import  Answer  from './Answer';
import Layout from './../Layout';
import LoginPage from './Login';
import NewsPage from './News';
import NewsActive, { LoaderNewsUpdate } from './NewsActive';

const errorMsg = '404 Відсуня сторінка';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NoPage error={errorMsg} />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/",
        element: <Home />,
        errorElement: <NoPage />,
      },
      {
        path: "/answer",
        element: <Answer />,
        errorElement: <NoPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
        errorElement: <NoPage />,
      },
      {
        path: "/news",
        element: <NewsPage />,
        errorElement: <NoPage />,
      },
      {
        path: `/news/:id`,
        element: <NewsActive />,
        errorElement: <NoPage />,
        loader: LoaderNewsUpdate
      },
    ],
  },
]);

function App() {

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
