import React from "react";
// import Slider from "./Slider";

import Container from 'react-bootstrap/Container'
import Slider22 from "./Slider_2";


export const Factory = () => {

  return (
    <Container >
      <div className="shop">
      <h2 className="shop__title title" >
        Виробництво
      </h2>
      <span className="shop__subtitle">
        На базі підприємства розташоване власне виробництво пресерв, копченої та слабосоленої продукції. Виготовляємо близько 100 найменувань. Додатково будується завод з виготовлення пресерв.
      </span>
      <Slider22 />
      </div>
    </Container>
  )
}