import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Footer = () => {
  const key = localStorage.getItem('user');

  return (
    <footer className="footer" id="footer">
      <Container>
        <div className="footer__top">
          <a href="/">
            <img src="../img/logo-footer.png" alt="logo" className="footer__logo" />
          </a>
          <nav className="footer__nav">
            <a href="https://kramline.com" className="footer__nav--item">Головна</a>
            <Link to="https://kramline.com/#calculate" className="footer__nav--item">Франшиза</Link>
            <a href="https://kramline.com/#advantages" className="footer__nav--item">Переваги</a>
            <a href="https://kramline.com/news" className="footer__nav--item">Новини</a>
            {(key) && (
              <>
              <a href="https://kramline.com/answer" className="footer__nav--item">Часті питання</a>
              </>
            )}

            <div className="footer__contact" id="scrff">
              <a href="#scrff" className="footer__nav--item">Контакти</a>
              <p>
                <a href="https://goo.gl/maps/Zxe67bHuUn5yPYGd9" className="footer__contact--link">
                  м. Львів, вул. Данила Апостола, 16х
                </a>
              </p>
              <p>
                <a href="tel:+380673051068" className="footer__contact--link">
                  +38 (067) 305 10 68
                </a>
              </p>
              <p>
                <a href="mailto:shop@kramar-ltd.com.ua" className="footer__contact--link">
                  franchise@kramar-ltd.com.ua
                </a>
              </p>
            </div>
          </nav>
        </div>
        <div className="footer__line"></div>
        <div className="footer__bottom">
          <div className="footer__desc">
            © 2023 TM Kramline. Усі права захищено.
          </div>
          <div className="footer__socials">
            <div className="footer__socials--text">
              Ми в соціальних мережах:
            </div>
            <a href="https://www.facebook.com/profile.php?id=61552778197070&locale=uk_UA" className="footer__socials--image footer__socials--image-facebook" target="_blank" rel="noreferrer"></a>
            <a href="https://instagram.com/kramline_franchise?igshid=MTNiYzNiMzkwZA%3D%3D&utm_source=qr" className="footer__socials--image footer__socials--image-inst" target="_blank" rel="noreferrer"></a>
            <a href="https://www.linkedin.com/company/llc-td-kramar-ltd/" className="footer__socials--image footer__socials--image-lk" target="_blank" rel="noreferrer"></a>
          </div>
        </div>
      </Container>
      
    </footer>
  )
}