import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

const advantages = [
  {
    id: 1,
    title: 'Власне виробництво',
    text: 'пресерв, слабосоленої та копченої продукції',
  },
  {
    id: 2,
    title: 'Автоматизація процесів',
    text: '',
  },
  {
    id: 3,
    title: 'Програма лояльності',
    text: '',
  },
  {
    id: 4,
    title: 'Не потрібно шукати постачальників',
    text: '',
  },
  {
    id: 5,
    title: 'Навчання персоналу',
    text: '',
  },
  {
    id: 6,
    title: 'Відпрацьована модель',
    text: 'на прикладі магазинів Kramar-shop',
  },
  {
    id: 7,
    title: 'Затишний інтер’єр',
    text: '',
  },
  {
    id: 8,
    title: 'Ексклюзивні позиції товару',
    text: '',
  },
]

export const Advantages = () => (
  <>
  <Container className="advantages">
    <Row>
      <Col md={12} >
        <h2 className="advantages__title title">
          Переваги приєднання до Kramline
        </h2>
      </Col>
    </Row>
    <Row>
    
      {advantages.map((advantag) => {
        const { title, text, id } = advantag;

        return (
          <Col key={id} xs={12} sm={6} md={4} lg={3}>
            <div className="advantages__card">
            <h3 className="advantages__card--title">
              {title}
            </h3>
            <span className="advantages__card--text">
              {text}
            </span>
            </div>
          </Col>
        );
      })}
      <div  id="advantages"></div>
    </Row>
  </Container >
  
  </>
);
