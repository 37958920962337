import React from "react";
import { Container } from "react-bootstrap";
import NewsSlider from "./NewsSlider";
import { useEffect } from "react";
import { useState } from "react";
import AxiosInstance from "../Axios";


const NewsActiveItem = ({ data, news }) => {
  
  const [currentData, setCurrentData] = useState(data);
  const [prev, setPrev] = useState(0);
  const [next, setNext] = useState(0);

  useEffect(() => {
    // Знаходимо об'єкт в масиві news з ідентифікатором, який співпадає з ідентифікатором в об'єкті data
    const foundObject = news.findIndex(item => item.id === currentData.id);
    const nextObject = news.findIndex(item => item.id === currentData.id)

    // Якщо знайдено, встановлюємо знайдений об'єкт як попередній
    if (foundObject !== -1 && foundObject > 0) {
      setPrev(news[foundObject - 1]);
    }

    if (nextObject !== -1 && nextObject < news.length) {
      setNext(news[nextObject + 1]);
    }
  }, [news]);


console.log(data)

  return (
    <>
      <div className="news-active__top">
        <img src={currentData.address} alt="alt" className="news-active__top--bg" />
        <div className="news-active__top--bg2"></div>
        <div className="news-active__top--content">
          <div className="news-active__top--title">{currentData.name}</div>
          <div className="news-active__top--wrapper">
            <div className="news-active__top--adress"> <div dangerouslySetInnerHTML={{ __html: currentData.shortDescription }} /></div>

          </div>
        </div>
      </div>
      <Container>
        <div className="news-active__content">


          <div className="news-active__content--top">
            <div className="news-active__top--date">{currentData.dateStart}</div>
            <div className="news-active__control">
            {news.map((item, index) => {
             return (
  (item.id === currentData.id && index !== 0 && index !== news.length - 1) && (
    <React.Fragment key={index}>
      <a href={`/news/${next.id}`} id="nb" className="news-active__control--prev">{'<  Попередня новина'}</a>
      <a href={`/news/${prev.id}`} id="pb" className="news-active__control--next">{'Наступна новина  >'}</a>
    </React.Fragment>
  )
  ||
  (item.id === currentData.id && index === news.length - 1) && (
    <React.Fragment key={index}>
      <a  href={`/news/${prev.id}`} id="pb" className="news-active__control--next">{'Наступна новина  >'}</a>
    </React.Fragment>
  )
  ||
  (item.id === currentData.id && index === 0) && (
    <React.Fragment key={index}>
      <a href={`/news/${next.id}`} id="nb" className="news-active__control--prev">{'<  Попередня новина'}</a>
    </React.Fragment>
  )
)})}
            </div>
          </div>
          <div className="news-active__content--text">
            <div dangerouslySetInnerHTML={{ __html: currentData.news_full }} />
          </div>
        </div>
      </Container>
      <Container>
        {(currentData.slider) && (<NewsSlider slider={currentData.slider} />)}
      </Container>
      <Container>
        {(currentData.contentImg) && (
          <div className="news-active__content--photo">
            <img src={currentData.contentImg} alt="" />
          </div>
        )}
      </Container>
      <div dangerouslySetInnerHTML={{ __html: currentData.htmlCode }} />
    </>
  )
}

export default NewsActiveItem;