import React, { useState } from "react";
import { HeaderTopF } from "./HeaderTopF";
import { HeaderTopBurgerF } from "./HeaderTopBurgerF";


const HeaderTopComponent = () => {
  const [isVisible, setIsVisible] = useState();

  const handleButtonClick = () => {
    setIsVisible((prevVisible) => !prevVisible);
  };
  return (
    <>
    {!isVisible && (
        <HeaderTopF handleButtonClick={handleButtonClick} />
      )}
      {isVisible && (
        <HeaderTopBurgerF handleButtonClick={handleButtonClick} />
      )}
      </>
  )
}

export default HeaderTopComponent;