import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logout from "../Logout";

export const HeaderTop = ({ handleButtonClick }) => {
  const key = localStorage.getItem('user');

  useEffect(() => {
    // Отримати всі елементи з класом header__nav-link
    let navLinks = document.querySelectorAll('.header__nav-link');

    // Отримати поточну URL-адресу
    let currentURL = window.location.href;
    let containsNews = currentURL.includes("news");
    let containsAns = currentURL.includes("answer");

    navLinks.forEach(function(link) {
      link.classList.remove('header__active');

      // Перевірте, чи елемент має клас news_la та додайте клас, якщо умова виконана
      if (link.classList.contains('news_la') && containsNews) {
        link.classList.add('header__active');
      }

      // Перевірте, чи елемент має клас answer та додайте клас, якщо умова виконана
      if (link.classList.contains('answer_la') && containsAns) {
        link.classList.add('header__active');
      }
    });

    // Тут можна додати будь-яку іншу логіку, яка має виконатися при зміні URL
  }, [window.location.href]); 
  const Logout  = () => {
    localStorage.setItem('user', '');
    localStorage.setItem('user_name', '');
    localStorage.setItem('user_data', '');
    localStorage.setItem('user_exp', '');
    window.location.replace("/");
  }
  return (
    <div className="header__top">
      <Container>
        <nav className="header__top--flex">
          <Link to="/">
            <img className="header__logo" src="../img/logo.svg" alt="logo" />
          </Link>
          <ul className="header__nav">
            <li>
              <Link to="/" className="header__nav-link">Головна</Link>
            </li>
            <li>
              <Link to="/#calculate" className="header__nav-link">Франшиза</Link>
            </li>
            <li>
              <Link to="/#advantages" className="header__nav-link">Переваги</Link>
            </li>

            <li>
              <Link to="/news" className="header__nav-link news_la">Новини</Link>
            </li>
            {(key) && (
              <li>
                <Link to="/answer" className="header__nav-link answer_la">Часті питання</Link>
              </li>
            )}
            <li>
              <a href="#scrff" className="header__nav-link">Контакти</a>
            </li>
            {(!key) ?
              <>
                <li>
                  <a href="/login" className="header__nav-link">
                    <img src="../img/profile.png" alt="profile" /></a>
                </li>
              </>
            :
              <>
                <li>
                  <a href="#"  onClick={Logout} className="header__nav-link">
                    Вийти  
                  </a>
                </li>
              </>
            }
          </ul>
          <div className="header__burger" onClick={handleButtonClick}>
            <div className="header__burger--item"></div>
            <div className="header__burger--item"></div>
            <div className="header__burger--item"></div>
          </div>
        </nav>
      </Container>
    </div>
  )
}