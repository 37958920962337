import React from "react";

// import { Answer } from "./pages/Answer";
// import { Home } from "./pages/Home";
import { Outlet } from "react-router-dom";



const Layout = () => {
  return (
    <>
      <Outlet />
    </>

  );
}

export default Layout;