import React, { useState } from "react";

const logout = () => {
    localStorage.setItem('user', '');
    localStorage.setItem('user_role', '');
    localStorage.setItem('user_name', '');
    localStorage.setItem('user_exp', '');
    localStorage.setItem('user_data', '');
    localStorage.setItem('ally-supports-cache', '');
    localStorage.setItem('products', '');

    window.location.replace("/").reload();

    return (
        <>
        </>
    )
}

export default logout;