/* eslint-disable no-undef */
import react from "react";
import { Container } from "react-bootstrap";



export const Maps = () => {
  let map;

  async function initMap() {
    // The location of Uluru
    const position = { lat: 49.83265734500057,  lng: 23.939450586505124, };
    const position2 = { lat: 49.5416155,  lng: 25.5613083, };
    const position3 = { lat: 49.5455575,  lng: 25.5600102, };
    const position4 = { lat: 49.56625873264195,  lng: 25.63764591999707, };
    const position5 = { lat: 50.40034586349995,  lng: 24.24153686729734, };
    // Request needed libraries.
    //@ts-ignore
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
  
    // The map, centered at Uluru
    map = new Map(document.getElementById("map"), {
      zoom: 9,
      center: position,
      mapId: "DEMO_MAP_ID",
    });
  
    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
      map: map,
      position: position,
      title: "Данили Апостола, 16, Х, Львів, Львівська область, Україна, 79040",
    });
    const marker2 = new AdvancedMarkerElement({
      map: map,
      position: position2,
      title: "вул. Будного, 32в, Тернопіль",
    });
    const marker3 = new AdvancedMarkerElement({
      map: map,
      position: position3,
      title: "вул.  Лучаківського, 5, Тернопіль",
    });
    const marker4 = new AdvancedMarkerElement({
      map: map,
      position: position4,
      title: 'м. Тернопіль, вул. Генерала Тарнавського, 34 (ТЦ "Площа Ринок")',
    });
    const marker5 = new AdvancedMarkerElement({
      map: map,
      position: position5,
      title: "м. Червоноград, вул. Шептицького, 1",
    });
  }
  
  initMap();

  return (
    <div className="map" id="map">


    </div>

  )
}