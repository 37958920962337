import React, { useState } from "react";
import { HeaderTop } from "./HeaderTop";
import { HeaderTopBurger } from "./HeaderTopBurger";

export const HeaderAns = () => {
  const [isVisible, setIsVisible] = useState();

  const handleButtonClick = () => {
    setIsVisible((prevVisible) => !prevVisible);
  };
  return (
    <>
      {!isVisible && (
        <HeaderTop handleButtonClick={handleButtonClick} />
      )}
      {isVisible && (
        <HeaderTopBurger handleButtonClick={handleButtonClick} />
      )}
    </>
  )
}