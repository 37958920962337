import React from "react";


const NoPage = () => {

  return (
    <>
      error 404
    </>
  )
}
export default NoPage;