import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import AxiosInstance from "./Axios";

// Запит за категоріями товарів


export const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(60);
  const [categoryTitle, setCategoryTitle] = useState('');
  const [docs, setDocs] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const urlApi = '/categories';
  const urlApi_2 = '/faqs';
  const user_data = localStorage.getItem('user_data');

  useEffect(() => {
    // AxiosInstance.get(`${urlApi}?page=1`).then((response) => {
    AxiosInstance.get(`${urlApi}?page=1&itemsPerPage=30`).then((response) => {
      if (response.status === 200)
        return (
          setCategories(response.data['hydra:member'])
        );
    });
  }, []);

  useEffect(() => {
    getCategoryFromServer(categoryId)
  }, []);
  const getCategoryFromServer = (id) => {
    AxiosInstance.get(`${urlApi_2}?page=1&itemsPerPage=30&category.id=${id}`).then((response) => {
      if (response.status === 200)
        setFaqs(response.data['hydra:member'])
    });

    let user_id = JSON.parse(user_data);
    AxiosInstance.get(`/users_docs?page=1&itemsPerPage=30&users.id=${user_id.id}`).then((response) => {
      if (response.status === 200)
        setDocs(response.data['hydra:member'])
    });

  }
  useEffect(() => {
    if (categoryId !== '') {
      getCategoryFromServer(categoryId);
    }
  }, [categoryId])

  useEffect(() => {
    if (categories.length > 0) {
      setCategoryId(categories[0].id)
    }
  }, [categories])

  const getCategotyId = (id, name) => {
    setCategoryTitle(name);
    setCategoryId(id);
  }


  function scrollAfterDelay() {
    setTimeout(function() {
      var contentElement = document.getElementById("content");

      if (contentElement) {
        // Прокрутити до елементу "content"
        contentElement.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    }, 150);
  }


  return (
    <Tab.Container defaultActiveKey="first" className="answer">
      <Row>
        <Col xs={12} className="answer__bg">
          <Container>
            <h2 className="answer__title">Часті запитання</h2>
            <Nav variant="pills" className="flex-row answer__tabs">

              {
                (categories.length > 0) &&
                categories.sort((a, b) => a.id - b.id).map((category) => {
                  return (

                    <Nav.Link eventKey={(categoryId === category.id) ? "first" : null} className="answer__tab" key={category.id} onClick={() => {

                      scrollAfterDelay()
                      getCategotyId(category.id, category.name)
                    }}>
                      <div dangerouslySetInnerHTML={{ __html: category.name }} />
                      <div>
                        {
                          (category.description !== undefined) &&
                          <span style={{ fontSize: '18px', fontWeight: '400', }}>
                            <div dangerouslySetInnerHTML={{ __html: category.description }} />
                          </span>
                        }
                      </div>
                    </Nav.Link>
                  )
                })
              }
            </Nav>
          </Container>
        </Col>

        <Col xs={12} className="answer__ac">
          <Container>
            <Tab.Content>
              <Tab.Pane eventKey='first'>
                <Accordion alwaysOpen className="acord" id="content">
                  <h2 className="answer__title-ac title">{categoryTitle}</h2>
                  {faqs.sort((a, b) => a.id - b.id).map((item) => {
                    return (
                      <Accordion.Item eventKey={item.id} className="acord__item">
                        <Accordion.Header>
                          <span className="acord__item--title">
                            <div dangerouslySetInnerHTML={{ __html: item.name }} />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          {item.contentShort !== '' && (
                            <p className="acord__item--text">
                              <div dangerouslySetInnerHTML={{ __html: item.contentShort }} />
                            </p>
                          )}
                          {item.contentFull !== '' && (
                            item.contentFull.includes('http') ? (
                              <a href={item.contentFull}>
                                <div dangerouslySetInnerHTML={{ __html: item.contentFull }} />
                              </a>
                            ) : (
                              <p className="acord__item--contact">
                                <div dangerouslySetInnerHTML={{ __html: item.contentFull }} />
                              </p>
                            )
                          )}
                          {(item.id === 54) && docs.map((aaa) => (
                            <>
                              <a href={process.env.REACT_APP_SERVER_URL + `/${aaa.media.contentUrl}`} target="_blanck">
                                {(aaa.name != undefined && aaa.name != null) ? aaa.name: 'Перейти на дизайн'}
                              </a>
                              <br />
                            </>
                          ))

                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })}
                </Accordion>
              </Tab.Pane>
            </Tab.Content>
          </Container>
        </Col>
      </Row>
    </Tab.Container>
  );
}
