import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logout from "../Logout";

export const HeaderTopF = ({ handleButtonClick }) => {
  const key = localStorage.getItem('user')
 
  

  return (
  <div className="header__top">
    <Container>
      <nav className="header__top--flex">
        <Link to="/">
          <img className="header__logo" src="../img/logo.svg" alt="logo" />
        </Link>
        <ul className="header__nav">
          <li>
            <Link to="/" className="header__nav-link header__active" id="lm">Головна</Link>
          </li>
          <li>
            <a href="#calculate" className="header__nav-link">Франшиза</a>
          </li>
          <li>
              <a href="#advantages" className="header__nav-link">Переваги</a>
            </li>
          <li>
            <Link to="/news" className="header__nav-link news_la">Новини</Link>
          </li>
          {(key) && (
              <>
            <li>
              <Link to="/answer" className="header__nav-link answer_la">Часті питання</Link>
            </li>
            </>
            )}
          <li>
            <a href="#scrff" className="header__nav-link">Контакти</a>
          </li>
          {(key) && (
              <>
            <li>
              <Link to="/" className="header__nav-link" onClick={logout}>Вийти</Link>
            </li>
            </>
            )}
          {(!key) && (
              <>
            <li>
              <Link to="/login" className="header__nav-link">
                <img src="../img/profile.png" alt="profile" /></Link>
            </li>
            </>
            )}
        </ul>
        <div className="header__burger" onClick={handleButtonClick}>
          <div className="header__burger--item"></div>
          <div className="header__burger--item"></div>
          <div className="header__burger--item"></div>
        </div>
      </nav>
    </Container>
  </div>
)}