import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import * as dayjs from 'dayjs';

export const SubForm = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const configAll = { headers: { 'content-type': 'application/json', } }
    const formAnswerFields = [
      {
        name: "name",
        value: name,
        formField: "/api/form_fields/2",
      },
      {
        name: "phone",
        value: phone,
        formField: "/api/form_fields/3",
      },
      {
        name: "city",
        value: city,
        formField: "/api/form_fields/4",
      },
    ]
    const article = {
      form: "/api/forms/2",
      media: [],
      dateEntered: dayjs().format(),
      "view": false,
      formAnswerFields: formAnswerFields,
    }
    axios.post(process.env.REACT_APP_SERVER_URL + '/api/form_answers', article, configAll)
      .then(() => {
        setName('');
        setPhone('');
        setCity('')
        toast.success("Дані відправлено в обробку!");
      })
      .catch(() => {
        setName('');
        setPhone('');
        setCity('')
        toast.error("Помилка при відправці даних");
      });
  }


  return (
    <>
    <div className="sub-form">
      <Container>
        <Row>
          
            <Col className="sub-form__title title">
              Бажаєте відкрити свій магазин?
            </Col>
         
        </Row>
        <Row>
          <Col className="sub-form__subtitle">
            Залишіть ваше ім’я та номер телефону й ми зв’яжемось з вами
          </Col>
        </Row>
        <Form className="form" onSubmit={handleSubmit} method="POST" >
          <Row className="justify-content-center">
            <Col sm={12} md={3} className="mb-3">
              <Form.Control className="form__inp" type="text" placeholder="Ваше ім'я*" required value={name} onChange={(e) => setName(e.target.value)} />
            </Col>
            <Col sm={12} md={3} className="mb-3">
              <Form.Control className="form__inp" type="phone" placeholder="Ваше місто*" required value={phone} onChange={(e) => setPhone(e.target.value)} />
            </Col>
            <Col sm={12} md={3} className="mb-3">
              <Form.Control className="form__inp" type="phone" placeholder="Номер телефону*" required value={city} onChange={(e) => setCity(e.target.value)} />
            </Col>
            <Col sm={12} md={3}>
              <Button className="form__btn" variant="primary" type="submit" >
                Залишити заявку
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <ToastContainer />
    </div>
    
    <div  id="sub_form"></div>
    </>
  )
}