import React, { useState } from 'react'
import axios from 'axios';
import jwt from 'jwt-decode' // import dependency;
import AxiosInstance from './Axios';


const Login = (props) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [visibleError, setVisibleError] = useState(false);
  // const navigate = useNavigate()

  const handleSubmit = event => {
    event.preventDefault();
    let v = false;
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      v = true;
    }
    setValidated(true);
    if (v) {
      const article = {
        username: login,
        password: password,
      };
      const response = axios.post(process.env.REACT_APP_SERVER_URL + '/auth', article).then((response) => {
        
        if (response.data.token != '') {
          let jwt_deecode = jwt(response.data.token);
          let isAdmin = false;
          // jwt_deecode.roles.map((role) => {
          // if (role === 'ROLE_ADMIN' || role === 'ROLE_MANAGER')
          // isAdmin = true;
          // });
          // if (isAdmin) {
          localStorage.setItem('user', response.data.token);
          if (jwt_deecode.roles.find(element => element == 'ROLE_ADMIN') == 'ROLE_ADMIN') {
            localStorage.setItem('user_role', 'ROLE_ADMIN');
          } else if (jwt_deecode.roles.find(element => element == 'ROLE_MANAGER') == 'ROLE_MANAGER') {
            localStorage.setItem('user_role', 'ROLE_MANAGER');
          }
          // localStorage.setItem('user_role', jwt_deecode.roles);
          localStorage.setItem('user_name', jwt_deecode.username);
          localStorage.setItem('user_exp', jwt_deecode.exp);
          setTimeout(() => {
            AxiosInstance.post('/users/me', {}, {
              headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${response.data.token}`,
              },
            }).then((response) => {
              localStorage.setItem('user_data', JSON.stringify(response.data));
              if (props.redirect != undefined) {
                window.location.reload();
              } else {

                window.location.replace("/answer");

              }
            });
          });
          // if (props.redirect != undefined) {
          //     window.location.reload();
          // } else {
          //     window.location.replace("/");
          // }
          // } else {
          // setVisibleError(true)
          // }
        } else
          setVisibleError(true)
      }).catch((e) => {
        if (e.code == 'ERR_NETWORK') {
          setVisibleError(true)
        }
      });


    }
  }

  return (
    <>
      <section className="login">
        <div className="login__faceHeader">
          <img src="../img/logo.svg" alt="" style={{'height': '80px'}} />
        </div>
        <div className="container">
          <div className="login__content">
            <div className="login__title">
              Введіть ваші дані для входу
            </div>
            <form action="#" className="login__form form" onSubmit={handleSubmit}
              noValidate
              validated={validated}>
                <div className="login__wrapper">
              <label htmlFor="login" className="form__label" >
                {/* Номер телефону/e-mail */}
                <input type="text" id="login" placeholder="Email/телефон" className="form__input" autoComplete="username" value={login} onChange={(e) => setLogin(e.target.value)} required />
              </label>

              <label htmlFor="password" className="form__label">
                {/* Пароль */}
                <input type="password" id='password' placeholder="Пароль*" className="form__input" autoComplete="current-password"
                  value={password} onChange={(e) => setPassword(e.target.value)} required />
              </label>
              </div>
              <button type="submit" className="form__button">Вхід</button>
            </form>
          </div>
        </div>
      </section>
    </>
  )

}

export default Login;