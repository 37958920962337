import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import '../../node_modules/swiper/swiper-bundle.min.css';

import Container from 'react-bootstrap/Container'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect, useState } from 'react';
import AxiosInstance from './Axios';

const Slider11 = () => {
  const [slide, setSlide] = useState([]);


  useEffect(() => {

    // AxiosInstance.get(`${urlApi}?page=1`).then((response) => {
    AxiosInstance.get(`https://api.kramline.com/api/menus?page=1&itemsPerPage=30&type=type_slider`).then((response) => {
      if (response.status === 200)
        return (
          setSlide(response.data['hydra:member'][0].menuItems)
          // console.log(response.data['hydra:member'][0].menuItems)
          // setCategories(response.data['hydra:member'])
        );
    });
  }, []);

  return (
    <Container>
      <Swiper 
      navigation={true}
      modules={[Navigation, Pagination, Scrollbar, Autoplay]}
      spaceBetween="10"
      loop={true}
      centeredSlides={true}
      initialSlide={1}
      slidesPerView={2}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false
      }}
      className='custom-slider'
      >
      {slide.map((item, index) => {
        return (
          <>
            < SwiperSlide key={item.id} > <img src={`https://api.kramline.com` + `${slide[index].slug}`} alt='' className='img' /></SwiperSlide>
          </>
        )
      })}
      {/* <SwiperSlide><img src='https://api.kramline.com/media/64da0dc81f7ce_slide-1.png' alt='' className='img' /></SwiperSlide>
        <SwiperSlide><img src='https://api.kramline.com/media/64da0e35102be_slide-2.png' alt='' className='img' /></SwiperSlide>
        <SwiperSlide><img src='https://api.kramline.com/media/64da0ee74a15a_slide-3.png' alt='' className='img' /></SwiperSlide>
        <SwiperSlide><img src='https://api.kramline.com/media/64da0dc81f7ce_slide-1.png' alt='' className='img' /></SwiperSlide> */}
    </Swiper>
    </Container >
  )
};

export default Slider11