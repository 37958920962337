import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'


const franchiseList = [
  {
    id: 1,
    text: 'Користування ТМ Kramline',
  },
  {
    id: 2,
    text: 'Забезпечення товаром від ТМ Kramline згідно із затвердженими планограмами',
  },
  {
    id: 3,
    text: 'Розробка дизайну магазину за рахунок франчайзі',
  },
  {
    id: 4,
    text: 'Підбір холодильного обладнання згідно з технічними кресленнями та дизайном',
  },
  {
    id: 5,
    text: 'Програмне забезпечення — CRM-система',
  },
  {
    id: 6,
    text: 'Навчання персоналу',
  },
  {
    id: 7,
    text: 'Консультація керівника франшизи',
  },
  {
    id: 8,
    text: 'Інструкція з відкриття магазинів',
  },
]


export const FranchiseList = () => (
  <Container className="franchise-list" id="franchise">
    <Row>
      <Col className="franchise-list__title title">
        У вартість франшизи входить:
      </Col>
    </Row>
    <Row>
      <Col>
        <ul>
          {franchiseList.map((franchiseItem) => {
            const { text, id } = franchiseItem;
            return (
              <li className="franchise-list__item" key={id}>
                {text}
              </li>
            )
          })}
        </ul>
      </Col>
    </Row>
  </Container>
);
