// import React from "react";
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import AxiosInstance from '../Axios';


const NewsList = ({ news }) => {
  const [visibleNews, setVisibleNews] = useState(8)
  
  useEffect(() => {
    if (visibleNews < news.length) {
      return;
    }
  }, [visibleNews]);

  const showMoreNews = () => {
    setVisibleNews(visibleNews + 4);
  }
  return (
    <>
    {/* <Container> */}
      <div className='news__container'>
        <div className="news">
          {/* Старий заголовок */}
          {/* <div className="news__title">
            
            Новини
          </div>
          <div className="news__subTitle">
            Нові магазини та події читайте у цьому розділі
          </div> */}
          <div className="news__content">
            {
              news.slice(0, visibleNews).map((item, index) => (
                <div className="news__item" key={index}>
                  <div className="newsItem__img">
                    {item.video_img ? <img src={item.video_img} alt="" /> : <img src="./../../img/news_1.png" alt="" />}
                  </div>
                  <div className="newsItem__wrapper">
                    <div className="newsItem__title">
                      {item.name}
                    </div>
                    <div className="newsItem__subTitle">
                      {item.news_shot}
                    </div>
                    <a href={`/news/${item.id}`} className="newsItem__btn">
                      Читати більше
                    </a>
                  </div>
                </div>
              ))
            }
          </div>
          {visibleNews < news.length && (
            <div className="news__moreBtn" onClick={() => {
              showMoreNews()
            }}>Показати більше</div>
          )}
        </div>
        </div>
        {/* </Container> */}
    </>
  )
}

export default NewsList;