import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './slider.css';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
// 
// import required modules
import { EffectCoverflow, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { useEffect } from 'react';
import AxiosInstance from '../Axios';

const NewsSlider = ({slider}) => {
  const [slide, setSlide] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosInstance.get(`https://api.kramline.com/api/menus?page=1&itemsPerPage=30&type=${slider}`)
      .then((response) => {
        if (response.status === 200) {
          setSlide(response.data['hydra:member'][0].menuItems);
          setLoading(false); // Помітка, що завантаження завершено
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Помітка, що завантаження завершено (навіть у випадку помилки)
      });
  }, [slider]); // Змінюючи slider, ми будемо робити новий запит

  if (loading) {
    // Поки компонент не завантажив дані, можна відобразити, наприклад, "Loading..."
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="news-slider">
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
          'marginBottom': '100px',
        }}
        navigation={true}
        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
        spaceBetween="70"
        loop={true}
        initialSlide={1}
        slidesPerView={1}
        pagination={{ clickable: true }}
        centeredSlides={true}
        // autoplay={{
        //   delay: 1700,
        //   disableOnInteraction: false
        // }}
        breakpoints={{
          600: {
            slidesPerView:3,
          },
        }}
        autoplayDisableOnInteraction={false}
        className='mySwiper'
        >
          {slide.map((item, index) => {
            return (
              <>
                <SwiperSlide key={item.id} >
                  <img src={`https://api.kramline.com` + `${slide[index].slug}`} alt='' className='img' />
                </SwiperSlide>
              </>
            )
          })}
        </Swiper>
      </div>
    </>
  );
}

export default NewsSlider;
