import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

export const Calculate = () => {
  const [squere, setSquere] = useState('40-60');
  const [rent, setRent] = useState('137 000');
  const [score, setScore] = useState('1 300 000');
  const [sum, setSum] = useState('27');
  const [kasa, setKasa] = useState('12 000');
  const [profit, setProfit] = useState('від 250');
  const [investing, setInvesting] = useState('160');
  const [profit2, setProfit2] = useState('104 500');

  return (
    <>
      <section className="calculate">
        <Container>
          <Row>
            <Col>
              <h2 className="calculate__title">Орієнтовні розрахунки франшизи</h2>
            </Col>
          </Row>
          <Row className="calculate__inputs">
            <Col xs={12} lg={3}>
              <InputGroup size="lg" className="calculate__input--width">
                <Form.Control className="calculate__input"
                  placeholder="00"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={squere}
                  onChange={(event) => {
                    setSquere(event.target.value)
                  }}
                  disabled
                />
              </InputGroup>
              <p className="calculate__input--text">Площа приміщення, м<sup>2</sup></p>
            </Col>
            <Col xs={12} lg={3}>
              <InputGroup size="lg" className="calculate__input--width">
                <Form.Control className="calculate__input"
                  placeholder="00"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={rent}
                  onChange={(event) => {
                    setRent(event.target.value)
                  }}
                  disabled
                />
              </InputGroup>
              <p className="calculate__input--text">Оренда+операційні витрати, грн/міс.</p>
            </Col>
            <Col xs={12} lg={3}>
              <InputGroup size="lg" className="calculate__input--width">
                <Form.Control className="calculate__input"
                  placeholder="00"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={score}
                  onChange={(event) => {
                    setScore(event.target.value)
                  }}
                  disabled
                />
              </InputGroup>
              <p className="calculate__input--text">Ремонт+обладнання, грн
                <br />
                <span style={{ fontSize: "0.8rem" }}>
                  *залежно від стану приміщення
                </span>
              </p>
            </Col>
            <Col xs={12} lg={3}>
              <InputGroup className="calculate__input--width">
                <Form.Control className="calculate__input"
                  placeholder="00"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={sum}
                  onChange={(event) => {
                    setSum(event.target.value)
                  }}
                  disabled
                />
              </InputGroup>
              <p className="calculate__input--text">Націнка, %</p>
            </Col>
            
      <div id="calculate"></div>
            <Col xs={12} lg={3}>
              <InputGroup size="lg" className="calculate__input--width">
                <Form.Control className="calculate__input"
                  placeholder="00"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={kasa}
                  onChange={(event) => {
                    setKasa(event.target.value)
                  }}
                  disabled
                />
              </InputGroup>
              <p className="calculate__input--text">Роялті фіксоване, грн</p>
            </Col>
            <Col xs={12} lg={3}>
              <InputGroup size="lg" className="calculate__input--width">
                <Form.Control className="calculate__input"
                  placeholder="00"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={profit}
                  onChange={(event) => {
                    setProfit(event.target.value)
                  }}
                  disabled
                />
              </InputGroup>
              <p className="calculate__input--text">Середній чек, грн/чек</p>
            </Col>
            <Col xs={12} lg={3}>
              <InputGroup size="lg" className="calculate__input--width">
                <Form.Control className="calculate__input"
                  placeholder="00"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={investing}
                  onChange={(event) => {
                    setInvesting(event.target.value)
                  }}
                  disabled
                />
              </InputGroup>
              <p className="calculate__input--text">Кількість чеків, шт</p>
            </Col>
            <Col xs={12} lg={3}>
              <InputGroup size="lg" className="calculate__input--width">
                <Form.Control className="calculate__input"
                  placeholder="00"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={profit2}
                  onChange={(event) => {
                    setProfit2(event.target.value)
                  }}
                  disabled
                />
              </InputGroup>
              <p className="calculate__input--text">Чистий дохід
                середній на місяць, грн</p>
            </Col>

          </Row>
        </Container>
      </section >
    </>
  );
}