import React from "react";
import Slider from "./Slider";

import Container from 'react-bootstrap/Container'


export const Shop = () => {

  return (
    <Container>
      <div className="shop">
      <h2 className="shop__title title" >
        Формат магазину
      </h2>
      <span className="shop__subtitle">
        Бажана площа приміщення від <span style={{ fontWeight: '700' }}>40</span>&nbsp;до&nbsp;<span style={{ fontWeight: '700' }}>60&nbsp;м<sup>2</sup></span>
      </span>
      <Slider />
      </div>
    </Container>
  )
}