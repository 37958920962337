import React, { useEffect, useState } from "react";

import { Header } from "../components/Header/Header";
import { History } from "../components/History";
import { Factory } from "../components/Factory";
import { Advantages } from "../components/advantages";
import { Calculate } from "../components/Calculete";
import { FranchiseList } from "../components/franchiseList";
import { Stages } from "../components/Stages";
import { Shop } from "../components/Shop";
import { SubForm } from "../components/SubForm";
import { Footer } from "../components/Footer";
import { Maps } from "../components/Maps";
import Map2 from "../components/Map2";
import { HeaderAns } from "../components/Header/HeaderAns";
import LastNews from "../components/News/LastNews";
import AxiosInstance from "../components/Axios";

const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const targetElement = document.getElementById('scrff');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth',
        block: "end" });
      }
    };

    // Перевірте, чи містить URL якір "#blog"
    if (window.location.hash === '#scrff') {
      handleScroll();
    }

    // Додаємо обробник прокрутки
    window.addEventListener('hashchange', handleScroll);

    // Знімаємо обробник при виході з компонента
    return () => {
      window.removeEventListener('hashchange', handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const targetElement = document.getElementById('advantages');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Перевірте, чи містить URL якір "#blog"
    if (window.location.hash === '#advantages') {
      handleScroll();
    }

    // Додаємо обробник прокрутки
    window.addEventListener('hashchange', handleScroll);

    // Знімаємо обробник при виході з компонента
    return () => {
      window.removeEventListener('hashchange', handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const targetElement = document.getElementById('calculate');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Перевірте, чи містить URL якір "#blog"
    if (window.location.hash === '#calculate') {
      handleScroll();
    }

    // Додаємо обробник прокрутки
    window.addEventListener('hashchange', handleScroll);

    // Знімаємо обробник при виході з компонента
    return () => {
      window.removeEventListener('hashchange', handleScroll);
    };
  }, []);


  const [news, setNews] = useState([])
  useEffect(() => {

    AxiosInstance.get(`https://api.kramline.com/api/news?page=1&itemsPerPage=30`).then((response) => {
      if (response.status === 200)
      return (
        setNews(response.data['hydra:member'])  
        );
    });
  }, []);
  console.log(news)

  return (
  <>
    <Header />
    {/* <HeaderAns /> */}
    <History />
    <Factory />
    <Advantages />
    <Calculate />
    <FranchiseList />
    <Stages />
    <Shop />
    <SubForm />
    <LastNews news={news} />
    {/* <Map2 /> */} 
    <Maps />
    <Footer />
  </>
)
  }
export default Home;